import * as Yup from 'yup';
import 'config/yupExtensions';
import Translator from 'components/ErrorTranslator';
import { STRING_NULLABLE } from './common';
import { email } from './contactInfo';

export const responsibility = (isMandatory: boolean) =>
  Yup.string().nullable().when('isContactPerson', {
    is: true,
    then: STRING_NULLABLE(isMandatory),
  });

export const isContactPerson = (_isMandatory: boolean) =>
  Yup.boolean().required(
    Translator('global_texts.errorMessages.errorMessageRequired', 'Required field'),
  );

export const contactDepartment = (isMandatory: boolean) =>
  Yup.string().nullable().when('isContactPerson', {
    is: true,
    then: STRING_NULLABLE(isMandatory),
  });

export const hasNgmPortalAccess = (_isMandatory: boolean) =>
  Yup.boolean().required(
    Translator('global_texts.errorMessages.errorMessageRequired', 'Required field'),
  );

export const invitationEmail = (isMandatory: boolean) =>
  Yup.string().when('hasNgmPortalAccess', {
    is: true,
    then: email(isMandatory),
  });

export const ngmAccessRole = (isMandatory: boolean) => Yup.string().isMandatory(isMandatory);

export const locations = Yup.array();

export const responsibilityStep = (isMandatory: boolean) =>
  Yup.object().shape({
    isContactPerson: isContactPerson(isMandatory),
    responsibility: responsibility(isMandatory),
    contactDepartment: contactDepartment(isMandatory),
  });

export const ngmPortalAccessStep = (isMandatory: boolean) =>
  Yup.object().shape({
    hasNgmPortalAccess: hasNgmPortalAccess(isMandatory),
    invitationEmail: invitationEmail(isMandatory),
    ngmAccessRole: ngmAccessRole(isMandatory),
  });
