import * as Yup from 'yup';
import 'config/yupExtensions';
import Translator from 'components/ErrorTranslator';

export const accountNumber = Yup.string().required(
  Translator(
    'online_payments.form.accountNumber.ruleErrorMessage',
    'Must be valid card, account or eVoucher number',
  ),
);

export const amount = Yup.number().required(
  Translator('online_payments.form.amount.ruleErrorMessage', 'Must be positive amount'),
);

export const note = Yup.string().notRequired();

export const location = Yup.string().required(
  Translator('online_payments.form.location.ruleErrorMessage', 'You must pick one location'),
);

export const smsCode = Yup.string().required(
  Translator(
    'online_payments.form.smsCode.ruleErrorMessage',
    'Enter valid SMS verification code',
  ),
);

export const identificationStep = Yup.object().shape({
  accountNumber,
  amount,
  note,
  location,
});

export const verificationStep = Yup.object().shape({
  smsCode,
});
