import {
  Location,
  LocationApiAxiosParamCreator,
  LocationApiFactory,
  LocationSoft,
} from 'generated';
import { instanceApi } from 'utils/axios';
import forceFileDownload from 'utils/forceFileDownload';
import createRequestUrl from 'api/utils/createRequestUrl';
import useRequest from 'api/useRequest';
import usePagedRequest, { onMountSWRInfiniteConfig } from 'api/usePagedRequest';
import { toArrayOrUndefined, trimFulltext } from 'common/filters';
import { LocationFilterValues } from 'components/Locations/LocationFilter/types';

const locationApi = LocationApiFactory(undefined, '', instanceApi);
const locationApiParamCreator = LocationApiAxiosParamCreator(undefined);

export const useLocationActivities = (contractId?: string, locale?: string) =>
  useRequest(
    locationApi.locationActivities,
    [contractId, undefined, locale],
    undefined,
    contractId !== undefined,
  );

export const useContractLocations = (
  contractId?: string,
  filterState?: Partial<LocationFilterValues>,
) =>
  usePagedRequest<Location, typeof locationApi.locationList>(
    locationApi.locationList,
    (pageIndex, pageSize) => [
      contractId!,
      toArrayOrUndefined(filterState?.states),
      filterState?.inactive,
      filterState?.approvedOnly,
      filterState?.withPaperProduct,
      trimFulltext(filterState?.fulltext),
      pageIndex,
      pageSize,
      filterState?.sortBy || undefined,
      filterState?.sortOrder === 'desc',
      undefined, // ngm-country passed externally
    ],
    contractId !== undefined,
    20,
    onMountSWRInfiniteConfig,
  );

export const useLocationDetail = (locationId?: string, fetchOnce = false) =>
  useRequest(
    locationApi.locationRead,
    [locationId],
    fetchOnce ? undefined : {},
    locationId !== undefined,
  );

export const usePosLocationsSearch = (posId: string, filterState?: Partial<LocationFilterValues>) =>
  usePagedRequest<LocationSoft, typeof locationApi.posLocationsSearch>(
    locationApi.posLocationsSearch,
    (pageIndex, pageSize) => [
      posId,
      trimFulltext(filterState?.fulltext),
      pageIndex,
      pageSize,
      undefined, // ngm-country passed externally
    ],
    true,
    20,
    onMountSWRInfiniteConfig,
  );

export const useLocationStates = (locationId?: string) =>
  useRequest(locationApi.locationStates, [locationId], {}, locationId !== undefined);

export const usePaymentTypes = (contractId?: string) =>
  useRequest(locationApi.paymentTypes, [contractId], undefined, contractId !== undefined);

export const exportLocations = async (contractId: string, filterState?: LocationFilterValues) => {
  const requestArgs = await locationApiParamCreator.locationListExport(
    contractId,
    filterState?.inactive,
    trimFulltext(filterState?.fulltext),
    filterState?.sortBy || undefined,
    filterState?.sortOrder === 'desc',
    undefined, // ngm-country passed externally
  );
  forceFileDownload(createRequestUrl(requestArgs), 'locations.csv');
};

export default locationApi;
