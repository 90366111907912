import useRequest from 'api/useRequest';
import { EmployeeFileApiFactory } from 'generated';
import { instanceApi } from 'utils/axios';

// Misc
const employeeFileApi = EmployeeFileApiFactory(undefined, '', instanceApi);

export const useEmployeeDocuments = (contractId?: string, isBoUser?: boolean) =>
  useRequest(
    employeeFileApi.employeeContractFileGetDocuments,
    [contractId],
    {},
    contractId !== undefined && isBoUser,
  );

export default employeeFileApi;
