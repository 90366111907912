import * as Yup from 'yup';
import createSchema from './createSchema';
import { ConfigField } from 'types/config';

const createArraySchema = (name: string, config: Record<string, ConfigField>) =>
  Yup.object().shape({
    [name]: Yup.array().of(createSchema(config)),
  });

export default createArraySchema;
