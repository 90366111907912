import Translator from 'components/ErrorTranslator';
import { mapValues } from 'lodash';
import * as Yup from 'yup';
import 'config/yupExtensions';

// TODO: update structure
export const products = (isMandatory: boolean) =>
  Yup.object().shape({
    products: Yup.array()
      .of(
        Yup.object().shape({
          productUid: Yup.string().isMandatory(isMandatory),
          payoutUid: Yup.string().isMandatory(isMandatory),
        }),
      )
      .isMandatory(
        isMandatory,
        Translator(
          'products.sectionTexts.chooseProductsFromTheSelection',
          'Choose products from the selection',
        ),
      ),
  });

export const payoutSchema = (isMandatory: boolean) => Yup.object().shape({
  payout: Yup.string().isMandatory(isMandatory),
  parameterCode: Yup.string(),
  partnerAccounts: Yup.lazy((obj) =>
    Yup.object(mapValues(obj, () => Yup.string())).when(
      'parameterCode',
      (parameterCode, schema) => {
        if (!parameterCode) return schema;
        return schema.concat(
          Yup.object({
            [parameterCode]: Yup.string()
              .isMandatory(true)
              .matches(
                /^[0-9]{8}$/,
                Translator(
                  'product_details.partnerAccounts.*.makro.validationRule',
                  'Invalid format',
                ),
              ),
          }),
        );
      },
    ),
  ),
});
