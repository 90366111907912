import * as Yup from 'yup';
import 'config/yupExtensions';
import IBAN from 'iban';
import Translator from 'components/ErrorTranslator';

const EUROZONE_PREFIXES = [
  'AT', // Austria
  'BE', // Belgium
  'HR', // Croatia
  'CY', // Cyprus
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'IE', // Ireland
  'IT', // Italy
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherlands
  'PT', // Portugal
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
];

const PREFIX_WEIGHTS = [10, 5, 8, 4, 2, 1];
const ACCOUNT_WEIGHTS = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1];

const modulo11 = (value: string | undefined, weights: number[]) => {
  const weightsSize = weights.length;

  if (!value || value?.length > weightsSize) {
    return false;
  }

  const startIndex = weightsSize - value.length;

  try {
    const sum = Array.from(value).reduce(
      (acc, currentChar, currentIndex) =>
        acc + Number(currentChar) * weights[startIndex + currentIndex],
      0,
    );
    return 0 === sum % 11;
  } catch (e) {
    return false;
  }
};

export const bankNumber = (_isMandatory: boolean) =>
  Yup.string()
    .matches(/^\d{1,6}$/, {
      message: Translator(
        'payment_info.form.bankNumber.ruleErrorMessage',
        'bad format 6 numbers max (e.g 123456)',
      ),
      excludeEmptyString: true,
    })
    .test(
      'modulo',
      Translator(
        'payment_info.form.bankNumber.ruleErrorMessage',
        'bad format 6 numbers max (e.g 123456)',
      ),
      (value) => !value || modulo11(value, PREFIX_WEIGHTS),
    );

export const accountNumber = (isMandatory: boolean) =>
  Yup.string()
    .isMandatory(isMandatory)
    .matches(
      /^\d{2,10}$/,
      Translator(
        'payment_info.form.accountNumber.ruleErrorMessage',
        'bad format 10 numbers max (e.g 1234567890)',
      ),
    )
    .test(
      'modulo',
      Translator(
        'payment_info.form.accountNumber.ruleErrorMessage',
        'bad format 10 numbers max (e.g 1234567890)',
      ),
      (value) => modulo11(value, ACCOUNT_WEIGHTS),
    );

export const accountNumberIBAN = (isMandatory: boolean) =>
  Yup.string()
    .isMandatory(isMandatory)
    .matches(
      /^[0-9a-zA-Z]{10,34}$/,
      Translator(
        'payment_info.form.accountNumber.ruleErrorMessage',
        'bad format 10 numbers max (e.g 1234567890)',
      ),
    )
    .test(
      'IBAN',
      Translator(
        'payment_info.form.accountNumber.ruleErrorMessage',
        'bad format 10 numbers max (e.g 1234567890)',
      ),
      (value) =>
        EUROZONE_PREFIXES.includes(`${value}`.toUpperCase().substring(0, 2)) &&
        value !== undefined &&
        IBAN.isValid(value),
    );
