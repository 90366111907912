import { PosApiFactory } from 'generated';
import { instanceApi } from 'utils/axios';
import useRequest from './useRequest';

const posApi = PosApiFactory(undefined, '', instanceApi);

export const useLocationPOS = (locationId?: string) =>
  useRequest(posApi.posList, [locationId], {}, locationId !== undefined);

export const usePOSStates = (posId?: string) =>
  useRequest(posApi.posStates, [posId], {}, posId !== undefined);

export default posApi;
