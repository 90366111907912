import Translator from 'components/ErrorTranslator';
import * as Yup from 'yup';
import { Message } from 'yup/lib/types';

function isMandatory<Schema extends Yup.BaseSchema>(
  this: Schema,
  isMandatory = true,
  msgKey?: Message<any>,
): Schema {
  return this.test({
    name: 'isMandatory',
    exclusive: true,
    message:
      msgKey || Translator('global_texts.errorMessages.errorMessageRequired', 'Required field'),
    test(value) {
      if (isMandatory) {
        // array and string have custom logic
        if (this.schema.type === 'array') {
          return Array.isArray(value) ? value.length > 0 : value !== undefined;
        }
        if (this.schema.type === 'string') {
          return value !== undefined && value !== null && value.toString().trim().length > 0;
        }
        if (this.schema.type === 'boolean') {
          return value === true;
        }
        // default logic for all other types
        return value !== undefined;
      }

      return true;
    },
  });
}

function oneOfSchemas<Schema extends Yup.BaseSchema>(
  this: Schema,
  schemas: Schema[],
  message?: Message<any>,
) {
  return this.test(
    'one-of-schemas-exact',
    message || 'Not all items in ${path} match one of the allowed schemas',
    (item) => schemas.some((schema) => schema.isValidSync(item, { strict: true })),
  );
}

Yup.addMethod(Yup.array, 'isMandatory', isMandatory);
Yup.addMethod(Yup.number, 'isMandatory', isMandatory);
Yup.addMethod(Yup.object, 'isMandatory', isMandatory);
Yup.addMethod(Yup.string, 'isMandatory', isMandatory);
Yup.addMethod(Yup.boolean, 'isMandatory', isMandatory);

Yup.addMethod(Yup.mixed, 'oneOfSchemas', oneOfSchemas);
