import * as Yup from 'yup';
import 'config/yupExtensions';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import Translator from 'components/ErrorTranslator';
import { EMAIL } from './common';

export const phoneNumber = (isMandatory: boolean) =>
  Yup.string()
    .isMandatory(isMandatory)
    .test(
      'isValidE164Format',
      Translator(
        'contact_info.form.phoneNumber.ruleErrorMessage',
        "Your phone number isn't corresponding to the international format",
      ),
      (value) => {
        try {
          if (!isMandatory && !value) return true;
          if (value !== undefined && isValidPhoneNumber(value)) {
            const phone = parsePhoneNumber(value);
            return phone?.country === 'BE'
              ? value === phone.format('E.164', { humanReadable: true })
              : true;
          }
          return false;
        } catch (e) {
          return false;
        }
      },
    );

export const email = (isMandatory: boolean) =>
  EMAIL(isMandatory, 'contact_info.form.email.ruleErrorMessage');
