import stripCompanyId from 'api/utils/stripCompanyId';
import * as Yup from 'yup';
import env from 'env';
import Translator from 'components/ErrorTranslator';
import 'config/yupExtensions';
import { CountryCode } from 'types/config';
import affiliationApi from 'api/affiliationApi';
import { EXCLUDE_HTTP_CALLBACKS } from 'utils/axios/interceptors';
import {
  FindMeResponse,
  FindMeResponseStatusBoEnum as StatusBoEnum,
  FindMeResponseStatusBoContractEnum as StatusBoContractEnum,
  FindMeResponseStatusNationalEnum as StatusNationalEnum,
} from 'generated/models';

const isProduction = 'production' === env.env;

export const MAGIC_ICO = '99923764';
export const MAGIC_ICO_BE = '1999.923.764';

const companyIdAsyncFactory = (callCache: Record<string, FindMeResponse>, performSearch: boolean) =>
  Yup.string().test('findMeCheck', async function (value: string) {
    let valid = false;
    let response: FindMeResponse | undefined = undefined;
    const country = this.parent.country as CountryCode;

    if (!isProduction && (value === MAGIC_ICO || value === MAGIC_ICO_BE)) {
      valid = true;
    } else {
      try {
        if (Object.prototype.hasOwnProperty.call(callCache, value)) {
          response = callCache[value];
        } else if (performSearch) {
          response = (
            await affiliationApi.affiliationFindMe(stripCompanyId(value), undefined, {
              EXCLUDE_HTTP_CALLBACKS,
            })
          ).data;
          callCache[value] = response; // save to the cache
        }
        // see https://czsodexo.atlassian.net/wiki/spaces/NI/pages/4908679606
        const statusNgm = response?.statusNgm;
        const statusNat = response?.statusNational || StatusNationalEnum.NotFound;
        const statusBo = response?.statusBo || StatusBoEnum.NotFound;
        const statusBoA = response?.statusBoContract || StatusBoContractEnum.NotFound;

        if (country === 'cz') {
          // https://czsodexo.atlassian.net/wiki/spaces/NI/pages/5288755201/NGM-2314+FindMe+UPDATE
          if (statusNgm) {
            return this.createError({
              message: Translator(
                'company_id.errorStates.companyActiveInNgm',
                'Company already registered, please login to see details',
              ),
            });
          }
          if (statusBo === StatusBoEnum.Active) {
            if (statusBoA === StatusBoContractEnum.Active) {
              return this.createError({
                message: Translator(
                  'company_id.errorStates.companyActiveInSfWithActiveContract',
                  'This company already has active Pluxee account. Please login to e-pluxee.cz instead.',
                ),
              });
            }
            if (statusBoA === StatusBoContractEnum.Inactive) {
              return this.createError({
                message: Translator(
                  'company_id.errorStates.companyActiveInSfWithInactiveContract',
                  'This company contract was not activated yet. Please contact infoline on info.partner@pluxee.com.',
                ),
              });
            }
            // StatusBoContractEnum.NotFound
            // continue
          } else if (statusBo === StatusBoEnum.Inactive) {
            return this.createError({
              message: Translator(
                'company_id.errorStates.companyInactiveInSf',
                'This company already has Pluxee account with invalid status. Please contact infoline on info.partner@pluxee.com.',
              ),
            });
          } else if (statusBo === StatusBoEnum.Error) {
            return this.createError({
              message: Translator(
                'company_id.errorStates.generalError',
                'Please try again later. In case the problem persists, please contact infoline on info.partner@pluxee.com.',
              ),
            });
          }
          if (statusNat === StatusNationalEnum.Ineligible) {
            return this.createError({
              message: Translator(
                'company_id.errorStates.companyIneligileInNr',
                'Your Company ID was not found in ARES. To continue, please contact infoline on info.partner@pluxee.com.',
              ),
            });
          }
          return true; // ngm=false; BO = [NotFound, null]; Nat = [Eligible, NotFound, null]
        } else if (country === 'be') {
          if (statusNgm) {
            return this.createError({
              message: Translator(
                'company_id.errorStates.companyActiveInNgm',
                'Company already registered, please login to see details',
              ),
            });
          }
          if (statusBo === StatusBoEnum.Active) {
            if (statusBoA === StatusBoContractEnum.Active) {
              return this.createError({
                message: Translator(
                  'company_id.errorStates.companyActiveInSfWithActiveContract',
                  'This company already has active Pluxee account. Please login to e-pluxee.cz instead.',
                ),
              });
            }
            if (statusBoA === StatusBoContractEnum.Inactive) return true;
            if (statusBoA === StatusBoContractEnum.NotFound) return true;
          }
          if (statusBo === StatusBoEnum.NotFound || statusBo === StatusBoEnum.Inactive) {
            if (statusNat === StatusNationalEnum.Eligible) return true;
            if (statusNat === StatusNationalEnum.Ineligible) {
              return this.createError({
                message: Translator(
                  'company_id.errorStates.companyIneligileInNr',
                  'Your Company ID was not found in ARES. To continue, please contact infoline on info.partner@pluxee.com.',
                ),
              });
            }
            if (statusNat === StatusNationalEnum.NotFound) {
              return this.createError({
                message: Translator(
                  'company_id.errorStates.companyNotFoundInNr',
                  'Company ID was not found. Please check if it’s correctly fill in and try it again.',
                ),
              });
            }
            if (statusNat === StatusNationalEnum.AresError) {
              return this.createError({
                message: Translator(
                  'company_id.errorStates.generalError',
                  'Please try again later. In case the problem persists, please contact infoline on info.partner@pluxee.com.',
                ),
              });
            }
          }
          if (statusBo === StatusBoEnum.Error) {
            return this.createError({
              message: Translator(
                'company_id.errorStates.generalError',
                'Please try again later. In case the problem persists, please contact infoline on info.partner@pluxee.com.',
              ),
            });
          }
        }
      } catch (e) {
        return this.createError({
          message: Translator(
            'company_id.errorStates.generalError',
            'Please try again later. In case the problem persists, please contact infoline on info.partner@pluxee.com.',
          ),
        });
      }
    }

    return valid;
  });

export const companyIdAsync = (callCache: Record<string, any>, performSearch: boolean = true) =>
  Yup.object().shape({
    companyId: companyIdAsyncFactory(callCache, performSearch),
  });

const companyId = (isMandatory: boolean) =>
  Yup.string()
    .isMandatory(isMandatory)
    .matches(
      /^\d{8}$/,
      Translator('company_id.form.companyId.ruleErrorMessageLength', 'Should be 8 numbers'),
    )
    .test(
      'last digit value',
      Translator('company_id.form.companyId.ruleErrorMessageInvalid', 'Invalid company ID'),
      (value) => {
        if (!value) {
          return false;
        }

        if (MAGIC_ICO === value && !isProduction) {
          return true;
        }

        try {
          const checkSum =
            Array.from(value.substr(0, 7)).reduce(
              (acc, currentChar, currentIndex) => acc + Number(currentChar) * (8 - currentIndex),
              0,
            ) % 11;

          let lastDigit = 0;
          if (0 === checkSum) {
            lastDigit = 1;
          } else if (1 === checkSum) {
            lastDigit = 0;
          } else {
            lastDigit = 11 - checkSum;
          }

          return Number(value[7]) === lastDigit;
        } catch (e) {
          return false;
        }
      },
    );

export const beCompanyId = (isMandatory: boolean) =>
  Yup.string()
    .isMandatory(isMandatory)
    .matches(
      /^[0-1]\d{3}\.\d{3}\.\d{3}$/,
      Translator('company_id.form.companyId.ruleErrorMessageLength', 'Should be 10 numbers'),
    )
    .test(
      'last digit value',
      Translator('company_id.form.companyId.ruleErrorMessageInvalid', 'Invalid company ID'),
      (value) => {
        if (!value) {
          return false;
        }

        if (MAGIC_ICO_BE === value && !isProduction) {
          return true;
        }

        try {
          const val = value.replace(/\./g, '');
          const newValue = val.length === 9 ? `0${val}` : val;

          const check = 97 - (Number(newValue.slice(0, 8)) % 97);
          return check === Number(newValue.slice(8, 10));
        } catch (e) {
          return false;
        }
      },
    );

export default companyId;
