import * as Yup from 'yup';
import 'config/yupExtensions';
import Translator from 'components/ErrorTranslator';

export const activity = Yup.string().required(
  Translator('global_texts.errorMessages.errorMessageRequired', 'Required field'),
);

export const subActivity = Yup.string().required(
  Translator('global_texts.errorMessages.errorMessageRequired', 'Required field'),
);

export const terActivity = Yup.string().required(
  Translator('global_texts.errorMessages.errorMessageRequired', 'Required field'),
);

const activities = (isMandatory: boolean) =>
  Yup.array()
    .of(
      Yup.object().shape({
        activity,
        subActivity,
        terActivity,
      }),
    )
    .isMandatory(
      isMandatory,
      Translator(
        'company_info.form.activity.ruleErrorMessage',
        'At least one activity is required',
      ),
    );

export default activities;
