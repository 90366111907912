import React from 'react';
import { extractNamespace, I18nProps, withLazyNamespaces } from 'i18n';

interface TranslatorProps extends I18nProps {
  id: string;
  defaultMessage: string;
}

export const Translator = ({ id, defaultMessage, tHtml }: TranslatorProps) => (
  <>
    {tHtml(id, defaultMessage)}
  </>
);

const ErrorTranslator = (id: string, defaultMessage: string) => {
  const namespace = extractNamespace(id);
  const Component = withLazyNamespaces(Translator, namespace);

  return function TranslatorError() {
    return <Component id={id} defaultMessage={defaultMessage} />;
  };
};

export default ErrorTranslator;
