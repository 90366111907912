import * as Yup from 'yup';
import 'config/yupExtensions';
import Translator from 'components/ErrorTranslator';

const badFormat = Translator(
  'company_info.form.streetName.ruleErrorMessage',
  'bad format, e.g rue du louvre (40)',
);

export const streetName = (isMandatory: boolean) =>
  Yup.string()
    .min(3, badFormat)
    .max(50, badFormat)
    .matches(/^([1-9][0-9]*)?[a-zA-Z\-_., À-ž0-9'`]+$/, badFormat)
    .isMandatory(isMandatory);

export const postalCode = (isMandatory: boolean) =>
  Yup.string()
    .matches(
      /^\d{3}([ ])?\d{2}$/,
      Translator('company_info.form.postalCode.ruleErrorMessage', 'bad format (e.g 123 45)'),
    )
    .isMandatory(isMandatory);

export const houseNumber = (isMandatory: boolean) =>
  Yup.string()
    .max(10, Translator('company_info.form.houseNumber.ruleErrorMessage', 'Max length 10 chars'))
    .isMandatory(isMandatory);

export const bePostalCode = (isMandatory: boolean) =>
  Yup.string()
    .matches(
      /^\d{4}$/,
      Translator('company_info.form.postalCode.ruleErrorMessage', 'bad format (e.g 1234)'),
    )
    .isMandatory(isMandatory);
