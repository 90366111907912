import { ContractFileApiAxiosParamCreator, ContractFileApiFactory } from 'generated';
import { instanceApi } from 'utils/axios';
import useRequest from './useRequest';
import forceFileDownload from 'utils/forceFileDownload';
import createRequestUrl from 'api/utils/createRequestUrl';

// Misc
const contractFileApi = ContractFileApiFactory(undefined, '', instanceApi);
const contractFileApiParamCreator = ContractFileApiAxiosParamCreator(undefined);

export const useContractDocuments = (contractId?: string) =>
  useRequest(contractFileApi.contractFileGetDocuments, [contractId], {}, contractId !== undefined);

export const downloadContractFile = async (contractId: string, fileId: string, fileName: string) => {
  const requestArgs = await contractFileApiParamCreator.contractGetFile(contractId, fileId);
  forceFileDownload(createRequestUrl(requestArgs), fileName);
};

export default contractFileApi;
