import { EXCLUDE_HTTP_CALLBACKS, posApi } from 'api';
import { buildPOS } from 'api/builders';
import Translator from 'components/ErrorTranslator';
import { TerminalFormValues } from 'components/Locations/types';
import { BelgiumValidationContext } from 'components/Onboarding/types';
import { ErrorResponse, PosTypeEnum } from 'generated/models';
import { get } from 'lodash';
import 'config/yupExtensions';
import * as Yup from 'yup';
import { StringSchema } from 'yup';
import { STRING } from './common';
import { email } from './contactInfo';

const mapPosErrors: Record<string, string> = {
  'pos.physical_terminal_id': 'terminalId',
};

export const posAsync = () =>
  Yup.mixed<TerminalFormValues>().test(
    'posCheck',
    async function (values: TerminalFormValues, { options, path }) {
      const callCache = options.context as Record<string, ErrorResponse | null>;
      let errorResponse: ErrorResponse | undefined | null = null;
      if (Object.prototype.hasOwnProperty.call(callCache, values.terminalId)) {
        errorResponse = callCache[values.terminalId];
      } else {
        try {
          await posApi.posValidate(buildPOS(values), undefined, {
            EXCLUDE_HTTP_CALLBACKS,
          });
          callCache[values.terminalId] = null; // save to the cache OK
        } catch (e) {
          errorResponse = e?.response?.data as ErrorResponse;
          callCache[values.terminalId] = errorResponse; // save to the cache
        }
      }

      if (errorResponse) {
        const error = errorResponse.errors?.[0];
        const fieldName = get(mapPosErrors, error?.property || '', 'terminalId');

        return this.createError({
          path: `${path}${path ? '.' : ''}${fieldName}`,
          message: Translator(
            `location_add.form.${fieldName}.requiredErrorMessage`,
            error?.message || 'Must be unique',
          ),
        });
      }

      return true;
    },
  );

export const posArrayAsync = (name: string) =>
  Yup.object().shape({
    [name]: Yup.array().of(posAsync()),
  });

export const posBank = (isMandatory: boolean) =>
  Yup.string().when('deviceType', {
    is: (type: PosTypeEnum) => PosTypeEnum.App !== type,
    then: STRING(isMandatory),
    otherwise: Yup.string().nullable(),
  });

export const posEmail = () =>
  Yup.string().when('deviceType', {
    is: (type: PosTypeEnum) => PosTypeEnum.App === type,
    then: Yup.string()
      .email(
        Translator(
          'location_add.form.email.ruleErrorMessage',
          'bad format (e.g. john.doe@pluxee.com)',
        ),
      )
      .required(Translator('global_texts.errorMessages.errorMessageRequired', 'Required field')),
    otherwise: Yup.string().nullable(),
  });

export const posAcceptedProducts = (isMandatory: boolean) =>
  Yup.array()
    .of(Yup.string())
    .isMandatory(
      isMandatory,
      Translator('location_add.form.productRequiredTerminalValidation', 'Required field'),
    );

export const posCertificate = (isMandatory: boolean) =>
  Yup.object().shape({
    certificateEmail: email(isMandatory),
  });

export const posTransfer = (isMandatory: boolean = true) =>
  Yup.object().shape({
    locationMasterId: Yup.string().isMandatory(isMandatory),
  });

export const posMarketplaces = (isMandatory: boolean) =>
  Yup.array()
    .of(Yup.string())
    .when('type', {
      is: (type: string) => type === 'physical',
      then: Yup.array().of(STRING(isMandatory)),
    });

export const posTerminalId = (isMandatory: boolean) =>
  Yup.string()
    .isMandatory(isMandatory, Translator('location_add.form.terminalId.ruleErrorMessage', 'Bad format 8 numbers'))
    .matches(
      /^\S*$/,
      Translator('location_add.form.terminalId.formatErrorMessage', 'No spaces in TID'),
    )
    .test('posUniqueness', function (value, { options, path }) {
      const context = options.context;
      if (context && value) {
        if (!(value in context)) {
          context[value] = true;
        } else {
          return this.createError({
            path,
            message: Translator(
              `location_add.form.terminalId.requiredErrorMessage`,
              'Must be unique',
            ),
          });
        }
      }
      return true;
    });

export const bePosTerminalId = (isMandatory: boolean) =>
  (Yup.string() as StringSchema<string, BelgiumValidationContext>)
    .isMandatory(isMandatory)
    .matches(/^[a-zA-Z0-9]+$/, {
      message: Translator(
        'location_add.form.terminalId.ruleErrorMessage',
        'Bad format only alphanumeric',
      ),
      excludeEmptyString: isMandatory,
    })
    .test(
      'posTidValidity',
      Translator(
        'location_add.form.terminalId.providerErrorMessage',
        'Invalid terminal ID, must be 8 digits',
      ),
      function (value, { options: { context } }) {
        const isTIDValid = context?.isTIDValid;
        if (isTIDValid && value) {
          return isTIDValid(value) ? /^\d{8}$/.test(value) : true;
        }
        return true;
      },
    );

export const bePosTerminalIdUnique = (isMandatory: boolean) =>
  bePosTerminalId(isMandatory).matches(/^\d{8}$/, {
    message: Translator('location_add.form.terminalId.ruleErrorMessage', 'bad format 8 numbers'),
    excludeEmptyString: isMandatory,
  });
