import Translator from 'components/ErrorTranslator';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';
import 'config/yupExtensions';

export const firstName = (isMandatory: boolean) =>
  Yup.string().isMandatory(
    isMandatory,
    Translator('contact_page.form.firstName.validation.required', 'You must enter your first name'),
  );

export const lastName = (isMandatory: boolean) =>
  Yup.string().isMandatory(
    isMandatory,
    Translator('contact_page.form.lastName.validation.required', 'You must enter your last name'),
  );

export const email = (isMandatory: boolean) =>
  Yup.string()
    .isMandatory(
      isMandatory,
      Translator('contact_page.form.email.validation.required', 'You must enter an email'),
    )
    .email(
      Translator('contact_page.form.email.validation.matches', 'You must enter a valid email'),
    );

export const topic = (isMandatory: boolean) =>
  Yup.string().isMandatory(
    isMandatory,
    Translator('contact_page.form.topic.validation.required', 'You must enter a topic'),
  );

export const subtopic = (isMandatory: boolean) =>
  Yup.string().isMandatory(
    isMandatory,
    Translator('contact_page.form.subTopic.validation.required', 'You must enter a subtopic'),
  );

export const phone = (isMandatory: boolean) =>
  Yup.string()
    .isMandatory(
      isMandatory,
      Translator(
        'contact_page.form.telephone.validation.required',
        'You must enter a telephone number',
      ),
    )
    .test(
      'isValidE164Format',
      Translator(
        'contact_page.form.telephone.validation.matches',
        'You must enter a valid telephone number',
      ),
      (value) => {
        try {
          return value !== undefined && isValidPhoneNumber(value);
        } catch (e) {
          return false;
        }
      },
    );

export const message = (isMandatory: boolean) =>
  Yup.string()
    .isMandatory(
      isMandatory,
      Translator('contact_page.form.message.validation.required', 'You must enter a message'),
    )
    .max(
      1500,
      Translator(
        'contact_page.form.message.validation.max',
        'Your message must be 1500 characters long maximum',
      ),
    );

export const captcha = (isMandatory: boolean) =>
  Yup.string().isMandatory(
    isMandatory,
    Translator(
      'contact_page.form.captcha.validation.required',
      'Please verify that you are a Human',
    ),
  );
