import Translator from 'components/ErrorTranslator';
import 'config/yupExtensions';
import * as Yup from 'yup';

const termsError = Translator(
  'contract_confirmation.form.termsAndConditions.termsErrorMessage',
  'You have to agree with our Terms and Conditions!',
);
const policyError = Translator(
  'contract_confirmation.form.gdpr.gdprErrorMessage',
  'You have to agree with our policy and conditions of use!',
);
const poaError = Translator(
  'contract_confirmation.form.powerOfAttorney.poaErrorMessage',
  'You have to upload a power of attorney',
);

export const terms = (isMandatory: boolean) =>
  Yup.bool()
    .test('termsAndConditions', termsError, (value) => true === value)
    .isMandatory(isMandatory, termsError);

export const policy = (isMandatory: boolean) =>
  Yup.bool()
    .test('gdpr', policyError, (value) => true === value)
    .isMandatory(isMandatory, policyError);

export const poaFilename = (isMandatory: boolean) =>
  Yup.string()
    .isMandatory(isMandatory, poaError)
    .when('powerOfAttorneyRequired', {
      is: true,
      then: Yup.string().required(poaError),
    });
