import * as Yup from 'yup';
import schemas from './schemas';
import { ConfigField } from 'types/config';

/**
 * Creates Yup object for given country configuration.
 *
 * createSchema({
 *   company_id: {
 *    visible: true,
 *    rule: 'CZ_COMPANY_ID'
 *    ...
 *   },
 *   ...
 * })
 *
 * @param config See companyStepShape.js for examples
 * @returns Yup.object
 */
const createSchema = (config: Record<string, ConfigField>): Yup.BaseSchema => {
  const fields = Object.entries(config);
  if (!config || !fields || fields?.length === 0) {
    console.error('No config provided to generate schema');
    return Yup.object().shape({});
  }
  const schema: Record<string, Yup.BaseSchema> = {};

  // TODO: handle mandatory, what is mandatory? a rule should handle it by itself
  fields.forEach(([field, { mandatory = false, visible, rule }]) => {
    if (visible) {
      if (rule && rule in schemas) {
        schema[field] = schemas[rule](mandatory);
      } else {
        schema[field] = schemas.STRING(mandatory);
        console.warn(`Rule '${rule}' doesn't exist for ${field}`);
      }
    }
  });

  return Yup.object().shape(schema);
};

export default createSchema;
