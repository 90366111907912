import { Employee, EmployeeApiFactory, LocationEmployeeItem, UserRoleEnum } from 'generated';
import { EmployeeFilterValues } from 'components/Employees/EmployeeFilter/types';
import { EmployeeLocationsFilterValues } from 'components/Employees/AssignedLocationsTable/types';
import { toArrayOrUndefined, trimFulltext } from 'common/filters';
import { instanceApi } from 'utils/axios';
import useRequest from './useRequest';
import usePagedRequest, { onMountSWRInfiniteConfig } from './usePagedRequest';

const employeeApi = EmployeeApiFactory(undefined, '', instanceApi);

export const useContractEmployees = (
  contractId: string | undefined,
  filterState: EmployeeFilterValues,
) =>
  usePagedRequest<Employee, typeof employeeApi.employeesList>(
    employeeApi.employeesList,
    (pageIndex, pageSize) => [
      contractId!,
      filterState.inactive,
      filterState.trait || undefined,
      toArrayOrUndefined(filterState.locationId),
      toArrayOrUndefined(filterState.userRoles) as UserRoleEnum[],
      toArrayOrUndefined(filterState.companyPositions as unknown as string),
      trimFulltext(filterState.fulltext),
      pageIndex,
      pageSize,
      filterState.sortBy || undefined,
      filterState.sortOrder === 'desc',
      undefined, // ngm-country passed externally
    ],
    contractId !== undefined,
    20,
    onMountSWRInfiniteConfig,
  );

// employeeId=null - to force employee locations fetch when creating new user
export const useEmployeeLocations = (
  contractId?: string,
  employeeId?: string | null,
  filterState?: EmployeeLocationsFilterValues,
) =>
  usePagedRequest<LocationEmployeeItem, typeof employeeApi.employeeLocationsGet>(
    employeeApi.employeeLocationsGet,
    (pageIndex, pageSize) => [
      contractId!,
      undefined, // ngm-country passed externally
      employeeId ?? undefined,
      trimFulltext(filterState?.fulltext),
      pageIndex,
      pageSize,
      filterState?.sortBy || undefined,
      filterState?.sortOrder === 'desc',
    ],
    contractId !== undefined && (employeeId !== undefined || employeeId === null),
    20,
    onMountSWRInfiniteConfig,
  );

export const useEmployeeDetail = (employeeId?: string) =>
  useRequest(employeeApi.employeeDetail, [employeeId], {}, employeeId !== undefined);

export const useEmployeeStates = (employeeId?: string) =>
  useRequest(employeeApi.employeeStates, [employeeId], {}, employeeId !== undefined);

export const useVerifyInvitation = (token?: string) =>
  useRequest(employeeApi.employeeInvitationVerify, [token], undefined, Boolean(token));

export default employeeApi;
