import { ValidationError } from 'yup';

/**
 * Transverse thrown ValidationError and append them to input object errors.
 * @param param Errors to be transversed.
 * @param errors Object to where errors should be saved to.
 */
const getValidationErrors = (param: ValidationError[], errors: Record<string, any>) => {
  param.forEach((error) => {
    if (error.path) errors[error.path] = error.message;
  });
};

export default getValidationErrors;
