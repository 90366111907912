export { default as createSchema } from './createSchema';
export { default as createArraySchema } from './createArraySchema';
export { default as getValidationErrors } from './getValidationErrors';

export { companyIdAsync, MAGIC_ICO_BE, MAGIC_ICO } from './schemas/companyId';
export {
  responsibilityStep as employeeResponsibilitySchema,
  ngmPortalAccessStep as employeePortalAccessSchema,
} from './schemas/employee';
export { beTerminals as beTerminalsSchema } from './schemas/location';
export {
  identificationStep as paymentsIdentificationSchema,
  verificationStep as paymentsVerificationSchema,
} from './schemas/onlinePayments';
export {
  posAsync,
  posArrayAsync,
  posCertificate as posCertificateSchema,
  posTransfer as posTransferSchema,
} from './schemas/pos';
export { products as productsSchema } from './schemas/products';
