import { CompanyApiFactory } from 'generated';
import { instanceApi } from 'utils/axios';
import useRequest from './useRequest';

const companyApi = CompanyApiFactory(undefined, '', instanceApi);

export const useCompanyPositions = () => useRequest(companyApi.companyPositions, []);

export const useCompanyServices = () => useRequest(companyApi.companyServices, []);

export default companyApi;
