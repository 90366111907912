import * as Yup from 'yup';
import 'config/yupExtensions';
import Translator from 'components/ErrorTranslator';

export const STRING = (isMandatory: boolean) => Yup.string().isMandatory(isMandatory);

export const STRING_NULLABLE = (isMandatory: boolean) => Yup.string().nullable().isMandatory(isMandatory);

export const INT = (isMandatory: boolean) => Yup.number().isMandatory(isMandatory);

export const BOOL = (isMandatory: boolean) => Yup.boolean().isMandatory(isMandatory);

export const EMAIL = (isMandatory: boolean, tKey: string) =>
  Yup.string()
    .isMandatory(isMandatory)
    .email(Translator(tKey, 'Bad format (e.g. john.doe@pluxee.com)'));
